<template>
    <div class="preview">
        <v-header :title="info.planTitle" deep></v-header>
        <van-swipe class="my-swipe" :initial-swipe="index" :loop="false" :show-indicators="false" @change="changeSwiper">
            <template v-for="(i, index) in images">
                <van-swipe-item>
                    <img v-lazy="i.picFullPath" @click="showBig(index)">
                </van-swipe-item>
            </template>

            <van-swipe-item v-if="!info.pay">
                <div class="swipe-end">
                    <img class="white_logo" src="https://cdn.001ppt.cn/h5/assets/images/white-logo.png" width="200">
                    <span>本方案一共{{count}}页，已免费看完{{images.length}}页</span>
                </div>
            </van-swipe-item>

        </van-swipe>

        <div class="trunk-group">
            <div class="location">
                <span>{{active+1}}/{{images.length}}</span>
            </div>
            <div class="trunk">
                <div class="trunk-scroll" :style="{width: `${images.length*90+(images.length-1)*8}px`}">
                    <template v-for="(i, index) in images">
                        <img v-lazy="i.picFullPath" @click="goIndex(index)" style=";width: 90px" width="90" :style="{marginLeft: index === 0 ? '0': '8px'}">
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {scheme} from '@/api'
    // import { ImagePreview } from 'vant';
    const {ImagePreview} = vant;
    export default {
        name: "preview",
        data(){
            return{
                images: [],
                index: 0,
                active: 0,
                imgWidth: 0,
                count: 0,
                info: {}
            }
        },
        created(){
            this.getInfo();
        },
        methods: {


            loadImg(e){
              this.imgWidth = e.target.width;
            },

            async getInfo(){
                this.$loading();
                try {
                    const {data, code} = await scheme.info(this.$route.params.id);
                    this.count = data.projPlanPicResources.length;
                    let temp = data.pay ? data.projPlanPicResources.length: data.supportLookFree;
                    this.info = data;
                    for(let i = 0; i < temp; i ++){
                        this.images.push(data.projPlanPicResources[i]);
                    }

                }finally {
                    this.$loading.close();
                }

            },

            changeSwiper(e){
                this.active = e;
                 document.querySelector('.trunk').scrollTo({left: (e+1)*8 + (e+1)*90,behavior: "smooth"});

            },
            goIndex(i){
                this.active = i;
                this.index = i;
            },


            showBig(index){

                ImagePreview({
                    images: this.images.map(i => i.picFullPath),
                    startPosition: index,
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .preview{
        height: 100vh;
        background-color: #000;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .my-swipe{
            width: 100%;
            img{
                width: 100%;
            }
        }

        .swipe-end{
            width: 100vw;
            height: 210px;
            background: var(--text-color);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img{
                width: 200px;
            }
            span{
                color: #fff;
                font-size: 12px;
                margin-top: 5px;
            }
        }


        .trunk-group{
            position: absolute;
            bottom: 50px;
            background-color: #000;
            width: 100vw;
            height: 60px;

            display: flex;
            flex-direction: column;
            .location{
                position: absolute;
                top: -25px;
                left: 28px;
                width: 32px;
                height: 15px;
                border-radius: 15px;
                background-color: #999;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    font-size: 12px;
                    color: #fff;
                    opacity: .5;
                }
            }
            .trunk{
                overflow-x: auto;
                margin-left: 28px;
                overflow-y: hidden;
                &::-webkit-scrollbar { width: 0 !important;height: 0 !important }
                .trunk-scroll{
                    img{
                        width: 90px;
                    }

                }
            }


        }

    }
</style>
